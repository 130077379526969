import panel from '../modules/panel';
import scroll from '../modules/scroll';
import dropdown from '../modules/dropdown';
import expander from '../modules/expander';
import popup from '../modules/popup';
import animations from '../modules/animations';
import carousel from '../modules/carousel';
import tabs from '../modules/tabs';
import actions from '../modules/actions';

const Ui = {
  init(element) {
    panel.init(element);
    scroll.init(element);
    dropdown.init(element);
    expander.init(element);
    popup.init(element);
    animations.init(element);
    carousel.init(element);
    tabs.init(element);
    actions.init(element);
  },
};

export default Ui;
