import axios from 'axios';

const Popup = {
  clearing: false,
  autoPopupDisplayed: false,

  init(element = document) {
    const triggers = Array.from(element.querySelectorAll('[data-popup]'));
    const autoTriggers = Array.from(
      element.querySelectorAll('[data-popup-auto]'),
    );

    if (triggers.length) {
      triggers.forEach((trigger) => {
        trigger.removeEventListener('click', this.handlePopup);
        trigger.addEventListener('click', this.handlePopup);
      });
    }

    if (autoTriggers.length) {
      window.addEventListener('load', (ev) => {
        autoTriggers.forEach((trigger) => {
          if (
            (trigger.dataset.popupAuto !== 'once' || !this.getPopupStorage()) &&
            !this.autoPopupDisplayed
          ) {
            const delay = trigger.dataset.popupDelay;

            if (!delay) {
              this.handlePopup(ev, trigger);
            } else {
              if (!Number.isNaN(delay)) {
                setTimeout(() => {
                  if (!this.autoPopupDisplayed) {
                    this.handlePopup(ev, trigger);
                    this.autoPopupDisplayed = true;
                  }
                }, parseInt(delay, 10));
              }

              if (trigger.dataset.popupBlur) {
                const autoPopupInterval = setInterval(() => {
                  if (!this.autoPopupDisplayed && !document.hasFocus()) {
                    this.handlePopup(ev, trigger);
                    this.autoPopupDisplayed = true;

                    // CLear interval
                    clearInterval(autoPopupInterval);
                  }
                }, 300);
              }
            }
          }
        });
      });
    }

    document.addEventListener('panels:close', this.clearPopup.bind(this));
    document.addEventListener('popup:close', this.clearPopup.bind(this));
  },

  handlePopup(ev, instance = null) {
    ev.preventDefault();

    const trigger = ev.currentTarget;
    const target = instance || trigger.dataset.popup;

    if (Popup.clearing) {
      return;
    }

    if (trigger && target) {
      // Show loader
      document.body.classList.add('is-loading');

      if (target === 'remote') {
        const targetURL = new URL(ev.currentTarget.href);

        // Set remote param
        targetURL.searchParams.set('remote', true);

        axios
          .get(targetURL)
          .then((response) => Popup.callbacks.insertTemplate(response.data));
      } else {
        const element = instance ? target : document.querySelector(target);

        if (element) {
          Popup.callbacks.insertTemplate(element.innerHTML);
        }
      }
    } else {
      Popup.clearPopup();
    }
  },

  clearPopup() {
    if (Popup.content) {
      Popup.clearing = true;

      // Set local storage
      this.setPopupStorage();

      setTimeout(() => {
        Popup.content.innerHTML = '';
        Popup.clearing = false;
      }, 500);
    }

    // Remove popup class
    document.body.classList.remove('has-popup-open');
  },

  getPopupStorage() {
    return window.localStorage.getItem('is-popup-closed');
  },

  setPopupStorage() {
    window.localStorage.setItem('is-popup-closed', true);
  },

  callbacks: {
    insertTemplate(template) {
      // Set popup content
      Popup.content = document.querySelector('[data-popup-content]');

      // Insert template
      Popup.content.innerHTML = template;

      // Add popup class
      document.body.classList.add('has-popup-open');

      // Hide loader
      document.body.classList.remove('is-loading');

      if (Popup.content.querySelector('iframe')) {
        Popup.content.parentNode.classList.add('has-iframe');
      }
    },
  },
};

export default Popup;
