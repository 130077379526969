/* eslint-disable no-new */
import Flickity from 'flickity';
import 'flickity-imagesloaded';

const Slider = {
  init(element = document) {
    const carousels = Array.from(element.querySelectorAll('[data-carousel]'));
    const defaults = {
      cellAlign: 'left',
      contain: true,
      imagesLoaded: true,
      percentPosition: false,
      pageDots: false,
      prevNextButtons: false,
      watchCSS: true,
      arrowShape:
        'M67.113.003a.806.806 0 0 1 1.353.873l-.055.085-36.229 49.117 36.23 49.12a.807.807 0 0 1-.093 1.062l-.078.065a.807.807 0 0 1-1.063-.093l-.065-.077-36.935-50.076L67.113.003Z',
    };

    // add this code
    /* eslint no-underscore-dangle: 0 */
    /* eslint func-names: 'off' */
    Flickity.prototype._createResizeClass = function () {
      this.element.classList.add('flickity-resize');
    };

    Flickity.createMethods.push('_createResizeClass');

    const { resize } = Flickity.prototype;
    Flickity.prototype.resize = function () {
      this.element.classList.remove('flickity-resize');
      resize.call(this);
      this.element.classList.add('flickity-resize');
    };

    if (carousels.length) {
      carousels.forEach((carousel) => {
        if (carousel.childElementCount > 1) {
          const options = carousel.dataset.carousel
            ? JSON.parse(carousel.dataset.carousel)
            : {};

          if (
            carousel.parentNode.dataset &&
            'carouselWrapper' in carousel.parentNode.dataset
          ) {
            this.unwrapElement(carousel.parentNode);
          }

          const flkty = new Flickity(carousel, { ...defaults, ...options });

          flkty
            .on('dragStart', () => {
              flkty.slider.style.pointerEvents = 'none';
            })
            .on('dragEnd', () => {
              flkty.slider.style.pointerEvents = 'auto';
            });
        }
      });

      // Refresh carousel on tab change
      window.addEventListener('tabs:show', (ev) => {
        const { hash } = ev.detail;

        if (hash) {
          const flkty = Flickity.data(`${hash} [data-carousel]`);

          if (flkty) {
            flkty.resize();
          }
        }
      });
    }
  },

  unwrapElement(el) {
    el.replaceWith(...el.childNodes);
  },
};

export default Slider;
