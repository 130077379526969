import panels from '../utils/panels';

const Dropdown = {
  openClass: 'is-open',

  init(element = document) {
    element.addEventListener('click', (ev) => {
      const path = ev.path || (ev.composedPath && ev.composedPath());

      if (path && panels.listenerMatcher(path, 'dropdown')) {
        this.handleDropdown(ev, ev.target.closest('[data-dropdown-parent]'));
      }
    });

    element.addEventListener(
      'dropdown:close',
      this.closeDropdowns.bind(this),
      false,
    );
  },

  handleDropdown(ev, dropdown, method = 'toggle') {
    if (ev) {
      // Close other dropdowns
      this.closeDropdowns(dropdown);
    }

    if (dropdown) {
      // Update dropdown class
      dropdown.classList[method](this.openClass);

      // Handle dropdown wrapper
      setTimeout(() => this.handleWrapper(dropdown), 0);
    }
  },

  closeDropdowns(exclude) {
    const dropdowns = Array.from(document.querySelectorAll('[data-dropdown]'));

    dropdowns.forEach((dropdown) => {
      const parent = dropdown.closest('[data-dropdown-parent]');

      if (dropdown !== exclude && parent && parent !== exclude) {
        this.handleDropdown(null, dropdown, 'remove');
        this.handleDropdown(null, parent, 'remove');
      }
    });
  },

  handleWrapper(dropdown) {
    const wrapper = dropdown.closest('[data-dropdown-wrapper]');

    if (wrapper) {
      wrapper.classList[
        wrapper.querySelector(`.${this.openClass}`) ? 'add' : 'remove'
      ]('has-dropdown-open');
    }
  },
};

export default Dropdown;
