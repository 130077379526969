import { delegate } from 'tippy.js';

const Animations = {
  init() {
    delegate('body', {
      target: '[data-tippy]',
      content(trigger) {
        const id = trigger.dataset.tippy;
        const template = document.querySelector(id);
        return template ? template.innerHTML : '';
      },
      trigger: 'click',
      allowHTML: true,
      delay: 0,
      touch: true,
      interactive: true,
      theme: 'ccf',
      maxWidth: 400,
      offset: [-20, -20],
      placement: 'bottom-start',
      animation: 'shift-toward',
      popperOptions: {
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
              padding: 32,
            },
          },
        ],
      },
      onShow(instance) {
        const closer = instance.popper.querySelector('[data-tippy-close]');

        if (closer) {
          closer.addEventListener('click', () => instance.hide());
        }

        // Bind custom global event
        document.addEventListener('panels:close', () => instance.hide());
      },
      onHide(instance) {
        const closer = instance.popper.querySelector('[data-tippy-close]');

        if (closer) {
          closer.removeEventListener('click', () => instance.hide());
        }

        // Unbind custom global event
        document.removeEventListener('panels:close', () => instance.hide());
      },
    });
  },
};

export default Animations;
