import axios from 'axios';

const Actions = {
  triggers: [],

  init(element = document) {
    this.triggers = Array.from(element.querySelectorAll('[data-action]'));

    if (this.triggers.length) {
      this.triggers.forEach((trigger) => {
        const event = trigger.dataset.actionEvent;

        if (event === 'load') {
          window.addEventListener(event, (ev) => this.handleEvent(ev, trigger));
        } else {
          trigger.addEventListener(event, (ev) => this.handleEvent(ev));
        }
      });
    }
  },

  handleEvent(ev, trigger) {
    const element = trigger || ev.currentTarget;

    if (element.getAttribute('action') === '#') {
      ev.preventDefault();
      ev.stopPropagation();
    }

    this.runAction(element, element.dataset);
  },

  runAction(trigger, data) {
    /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["data"] }] */
    const formData = new FormData(
      trigger.hasAttribute('action') ? trigger : document.createElement('form'),
    );

    // Enable loading indicator
    trigger.classList.add('is-loading');

    // Extend formData with trigger data attributes
    Object.keys(data).forEach((key) => {
      if (key === 'actionData') {
        const actionData = JSON.parse(data[key]);

        Object.keys(actionData).forEach((dataKey) => {
          formData.append(dataKey, actionData[dataKey]);
        });
      } else {
        formData.append(key, data[key]);
      }
    });

    if (trigger.hasAttribute('name')) {
      const option = trigger.options[trigger.selectedIndex];

      if (option && typeof option.value !== 'undefined') {
        data.value = option.dataset.value;
        formData.append('value', option.value);
      }
    }

    axios
      .post(window.globals.ajax_url, formData, {
        processData: false,
        contentType: 'multipart/form-data',
      })

      // Run action callback
      .then((response) => {
        if (this.callbacks[data.action]) {
          setTimeout(
            () => this.callbacks[data.action](trigger, response, data),
            250,
          );
        }
      })

      // Log errors
      .catch((response) => {
        if (this.callbacks[data.action]) {
          setTimeout(
            () => this.callbacks[data.action](trigger, response, data),
            500,
          );
        }
      })

      // Disable loading indicator
      .then(() =>
        setTimeout(() => trigger.classList.remove('is-loading'), 250),
      );
  },

  updateTrigger(trigger, data) {
    if (data.finished) {
      trigger.parentNode.remove();
    } else {
      trigger.setAttribute('data-page', parseInt(trigger.dataset.page, 10) + 1);
    }
  },

  callbacks: {
    contact: (trigger, { data }, { actionSalted }) => {
      const content = trigger.querySelector(
        `[data-action-content="${actionSalted}"]`,
      );
      const message = trigger.querySelector(
        `[data-action-message="${actionSalted}"]`,
      );

      if (data.type === 'page') {
        window.location = data.value;
      } else if (message) {
        message.classList.add('is-visible');
        message.dataset.status = data.status;
        message.innerHTML = data.value.replace(new RegExp('\\\\', 'g'), '');

        if (data.status === 'success') {
          content.style.display = 'none';
        }
      }
    },

    load_posts: (trigger, { data }, { action }) => {
      const element = document.querySelector(`[data-action-element=${action}]`);

      if (element && data.data) {
        if (data.data.posts && data.data.posts.length > 0) {
          data.data.posts.forEach((post) => {
            trigger.parentNode.insertAdjacentHTML('beforebegin', post);
          });
        } else if (data.data.message) {
          trigger.parentNode.insertAdjacentHTML(
            'beforeend',
            `<p class="u-notice">${data.data.message}</p>`,
          );
        }

        // Update the "Load more" button
        Actions.updateTrigger(trigger, data.data);
      }
    },
  },
};

export default Actions;
