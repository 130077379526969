const Expander = {
  triggers: [],
  eventsBlocked: false,

  init(element = document) {
    this.triggers = Array.from(element.querySelectorAll('[data-expand]'));

    if (this.triggers.length) {
      this.triggers.forEach((trigger) => {
        trigger.addEventListener('click', this.handleExpander.bind(this));
      });
    }
  },

  handleExpander(ev) {
    if (ev.target.tagName.toLowerCase() === 'a') {
      return;
    }

    ev.preventDefault();

    const selector = ev.currentTarget.dataset.expand;

    if (selector && !this.eventsBlocked) {
      const target = document.querySelector(selector);

      if (target) {
        const group = target.closest('[data-expand-group]');

        if (target.classList.contains('is-expanded')) {
          this.collapseElement(target, target.closest('[data-expand-animate]'));
        } else {
          this.expandElement(target, target.closest('[data-expand-animate]'));
        }

        if (group) {
          this.closeExpanders(group, target);

          group.classList.toggle(
            'has-expanded',
            target.classList.contains('is-expanded'),
          );
        }
      }
    }

    // Block the events
    this.eventsBlocked = true;

    setTimeout(() => {
      // Unblock the events
      this.eventsBlocked = false;
    }, 300);
  },

  closeExpanders(group, except) {
    const triggers = Array.from(group.querySelectorAll('[data-expand]'));

    if (triggers.length) {
      triggers.forEach((trigger) => {
        const target = document.querySelector(trigger.dataset.expand);

        if (target && target !== except) {
          this.collapseElement(target, target.closest('[data-expand-animate]'));
        }
      });
    }
  },

  collapseElement(element, animate = false) {
    /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["element"] }] */
    if (!element.classList.contains('is-expanded')) {
      return;
    }

    if (animate) {
      const sectionHeight = element.scrollHeight;

      const elementTransition = element.style.transition;
      element.style.transition = '';

      requestAnimationFrame(() => {
        element.style.height = `${sectionHeight}px`;
        element.style.transition = elementTransition;

        requestAnimationFrame(() => {
          element.style.height = `${0}px`;
        });
      });
    }

    element.classList.remove('is-expanded');
  },

  expandElement(element, animate = false) {
    /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["element"] }] */
    if (element.classList.contains('is-expanded')) {
      return;
    }

    if (animate) {
      const sectionHeight = element.scrollHeight;

      const transitionCallback = () => {
        element.removeEventListener('transitionend', transitionCallback);
        element.style.height = 'auto';
      };

      element.style.height = `${sectionHeight}px`;

      element.addEventListener('transitionend', transitionCallback);
    }

    element.classList.add('is-expanded');
  },
};

export default Expander;
